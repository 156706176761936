.brick-item .brick-title {
  padding: 0 20px;
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brick-item .brick-body {
  padding: 20px;
}
.extra {
  display: block;
  margin-top: 20px;
  color: #b2b2b2;
  font-size: 12px;
  line-height: 0.8;
}
.goods-video-box {
  margin-bottom: 10px;
}
.goods-video-box .goods-video {
  width: 290px;
  height: 140px;
}
.select-time {
  margin-top: 20px;
}
.goods-detail {
  max-width: 750px;
}
.tab-content {
  height: calc(100vh - 280px);
  overflow-y: scroll;
}
.form-bar {
  position: fixed;
  bottom: 0;
  left: 284px;
  width: calc(100vw - 308px);
  line-height: 80px;
  text-align: center;
  background-color: #fff;
  z-index: 2;
}
